.footer {
  color: var(--white);
  padding-block: var(--s14);
}

.footer .big-text-link svg {
  overflow: visible;

  text {
    font-family: var(--sofia-pro);
    font-weight: 300;
  }
}

.footer-cta {
  text-align: center;

  a {
    color: var(--highlighter-yellow-green);
  }
}