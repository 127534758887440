//https://every-layout.dev/layouts/center/

.l-center {
  --cr-max-width: var(--bp-64);

  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--cr-max-width);
  display: flex;
  flex-direction: column;
  padding-right: var(--cr-padding, 0);
  padding-left: var(--cr-padding, 0);
  align-items: var(--cr-align, initial);
}
