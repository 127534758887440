@use "../../0-settings/breakpoints";
@use "../../0-settings/scale";
@use '../../0-settings/clamp-helper' as *;

$_bp: #{breakpoints.screen-width(54)}em;

.letter {
  position: relative;
  z-index: 2;
  padding-bottom: 18rem;

  @include breakpoints.get(40) {
    padding-bottom: 0;
  }
}

.letter > p {
  max-width: 52rem;
  margin-top: var(--vertical-rhythm);

  &:first-child {
    margin-top: 0;
  }
}

.letter-signature {
  line-height: 1.5;
  
  @include breakpoints.get($_bp) {
    margin: 0;
  }
}

.letter-matt-image {
  max-width: clamp(15.625rem, #{getVW(29.375)}, 29.375rem);
  float: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50% 0%;

  @include breakpoints.get(40) {
    position: static;
    translate: none;
    float: right;
    shape-outside: circle();
    shape-margin: var(--s8);
    padding: var(--s0);
  }
}


.letter-negative-margin {
  margin-top: var(--s8);
  
  @include breakpoints.get(64) {
    margin-top: calc(var(--s7) * -1);
  }

  @include breakpoints.get(75) {
    margin-top: calc(var(--s14) * -1);
  }
}