@use "../0-settings/breakpoints";
@use "../0-settings/top-bar";

.top-bar {
  width: 100%;
  color: var(--white);
  padding: var(--s8) var(--mobile-padding);
}

.top-bar-tagline {
  display: none;

  @include breakpoints.get(40) {
    display: block;
  }
}