@use '../../0-settings/breakpoints' as *;

.past-report-row {
  --icon-arrow-color: var(--white);

  color: var(--white);
  position: relative;
}

.past-report-row .title {
  font-size: var(--s4);
  font-weight: 800;
}
.past-report-row a {
  text-decoration: none;
  font-weight: 700;
}

.past-report-row-purple-blob {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;

  @include get(64) {
    opacity: 1;
  }
}