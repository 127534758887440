//Class authoring guidelines
// .u-color-[color],
// .u-color-[color] > * {
//   color: var(--color-name) !important;
// }

// .u-color-[color]-bg {
//   background-color: var(--color-name) !important;
// }

.u-color-light-peach {
  color: var(--light-peach) !important;
}

.u-color-white {
  color: var(--white) !important;
}

.u-color-black {
  color: var(--black) !important;
}

.u-color-brown-gray {
  color: var(--brown-gray) !important;
}

.u-color-white-bg {
  background-color: var(--white) !important;
}

.u-color-black-bg {
  background-color: var(--black) !important;
}

.u-color-dark-gray-bg {
  background-color: var(--dark-gray) !important;
}
