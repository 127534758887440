.u-flex {
  display: flex !important;
}

// .u-flex-direction-column {
//   flex-direction: column !important;
// }

// .u-flex-direction-row {
//   flex-direction: row !important;
// }

// .u-flex-wrap {
//   flex-wrap: wrap !important;
// }

// .u-flex-no-wrap {
//   flex-wrap: nowrap !important;
// }

// .u-justify-content-between {
//   justify-content: space-between !important;
// }

.u-justify-content-center {
  justify-content: center !important;
}

// .u-justify-content-start {
//   justify-content: flex-start !important;
// }

// .u-justify-content-end {
//   justify-content: flex-end !important;
// }

.u-align-items-center {
  align-items: center !important;
}

// .u-align-items-start {
//   align-items: flex-start !important;
// }
