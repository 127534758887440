.u-position-relative {
  position: relative !important;
}

// .u-position-absolute {
//   position: absolute !important;
// }

// .u-position-static {
//   position: static !important;
// }

.u-overflow-hidden {
  overflow: hidden !important;
}

// .u-overflow-x-hidden {
//   overflow-x: hidden !important;
// }

// .u-overflow-visible {
//   overflow: visible !important;
// }

.u-scroll-behavior-auto, .u-scroll-behavior-auto * {
  scroll-behavior: auto !important;
}