// .u-font-size-s-4,
// .u-font-size-s-4 > * {
//   font-size: var(--s-4) !important;
// }

// .u-font-size-s-3,
// .u-font-size-s-3 > * {
//   font-size: var(--s-3) !important;
// }

// .u-font-size-s-2,
// .u-font-size-s-2 > * {
//   font-size: var(--s-2) !important;
// }

// .u-font-size-s-1,
// .u-font-size-s-1 > * {
//   font-size: var(--s-1) !important;
// }

// .u-font-size-s0,
// .u-font-size-s0 > * {
//   font-size: var(--s0) !important;
// }

// .u-font-size-s1,
// .u-font-size-s1 > * {
//   font-size: var(--s1) !important;
// }

// .u-font-size-s2,
// .u-font-size-s2 > * {
//   font-size: var(--s2) !important;
// }

// .u-font-size-s3,
// .u-font-size-s3 > * {
//   font-size: var(--s3) !important;
// }

// .u-font-size-s4,
// .u-font-size-s4 > * {
//   font-size: var(--s4) !important;
// }

.u-font-size-s5,
.u-font-size-s5 > * {
  font-size: var(--s5) !important;
}

// .u-font-size-s6,
// .u-font-size-s6 > * {
//   font-size: var(--s6) !important;
// }

// .u-font-size-s7,
// .u-font-size-s7 > * {
//   font-size: var(--s7) !important;
// }

// .u-font-size-s8,
// .u-font-size-s8 > * {
//   font-size: var(--s8) !important;
// }

// .u-font-size-s9,
// .u-font-size-s9 > * {
//   font-size: var(--s9) !important;
// }

// .u-font-size-s10,
// .u-font-size-s10 > * {
//   font-size: var(--s10) !important;
// }

.u-font-size-fluid-s5-s3 {
  font-size: clamp(var(--s3), 2vw, var(--s5)) !important;
}

// .u-font-weight-400 {
//   font-weight: 400 !important;
// }

// .u-font-weight-500 {
//   font-weight: 500 !important;
// }

// .u-font-weight-600 {
//   font-weight: 600 !important;
// }

// .u-font-weight-700 {
//   font-weight: 700 !important;
// }

.u-font-weight-800 {
  font-weight: 800 !important;
}

// .u-font-weight-900 {
//   font-weight: 900 !important;
// }

.u-text-align-center {
  text-align: center !important;
}

// .u-text-transform-uppercase {
//   text-transform: uppercase !important;
// }

.u-line-height-1 {
  line-height: 1 !important;
}

.u-line-height-1\.3 {
  line-height: 1.3 !important;
}
