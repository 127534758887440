@use 'sass:map';
@use '../../0-settings/clamp' as *;
@use '../../0-settings/scale' as *;
@use '../../0-settings/breakpoints' as *;

.transformation-hero .image {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #D6D4D4;
    mix-blend-mode: multiply;
  }
}

.transformation-hero h3 {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  translate: -50% -50%;
  color: var(--white);
  font-size: clamp(var(--s6), #{getVW(map.get($sizes, 's13'))}, var(--s13));
  max-width: 20ch;
  font-weight: 900;
  line-height: 1.1;
  width: 100%;
  text-align: center;
  padding-inline: var(--mobile-padding);
  text-wrap: balance;

  @include get(40) {
    top: auto;
    bottom: var(--s17);
    translate: -50% 0;
    padding-inline: 0;
  }
}

.transformation-hero p {
  text-align: center;
  font-size: var(--s1);
}