@use "../0-settings/animation";
@use "../0-settings/breakpoints";
@use "../0-settings/offcanvas";
@use "../0-settings/scale";

.offcanvas {
  visibility: hidden;
  --offcanvas-padding: var(--s4);
  position: fixed;
  transform: translate3d(100%, 0, 0);
  height: 100vh;
  top: 0;
  right: 0;
  width: 100%;
  max-width: var(--offcanvas-max-width);
  background-color: var(--duck-egg-blue);
  z-index: 7;
  padding-top: var(--s8);
  padding-left: var(--offcanvas-padding);
  padding-right: var(--offcanvas-padding);
  overflow: auto;
  transition: transform 300ms #{animation.easing(easeoutquad)};
}

.offcanvas.-open {
  transform: translate3d(0, 0, 0);
}

.offcanvas > * {
  @include breakpoints.get(1480px) {
    max-width: #{scale.calc-rem(528)}rem;
  }
}

.offcanvas-header button {
  margin-left: auto;
}

.offcanvas-tagline {
  display: none;

  @include breakpoints.get(30) {
    display: block;
  }
}

.offcanvas-footer {
  font-size: var(--s0);
  max-width: 45ch;
  line-height: 1.3;

  @include breakpoints.get(40) {
    margin-block: auto;
  }
}

.offcanvas-footer-description {
  display: none;

  @include breakpoints.get(48) {
    display: block;
  }
}

.offcanvas-footer-mobile-heading {
  display: block;

  @include breakpoints.get(48) {
    display: none;
  }
}

.offcanvas-icon > * {
  fill: var(--duck-egg-blue);
  transition: fill 0.2s ease;
}

.offcanvas-icon-open > * {
  fill: var(--black);
}

.offcanvas-endstate {
  width: 100%;
  height: 1px;
  max-width: #{scale.calc-rem(328)}rem;
  display: none;

  @include breakpoints.get(#{offcanvas.$offcanvas-endstate-bp}em) {
    display: block;
  }
}
