@use "../0-settings/breakpoints";

// .u-padding-all-s-7 {
//   padding: var(--s-7) !important;
// }

// .u-padding-all-s-6 {
//   padding: var(--s-6) !important;
// }

// .u-padding-all-s-5 {
//   padding: var(--s-5) !important;
// }

// .u-padding-all-s-4 {
//   padding: var(--s-4) !important;
// }

// .u-padding-all-s-3 {
//   padding: var(--s-3) !important;
// }

// .u-padding-all-s-2 {
//   padding: var(--s-2) !important;
// }

// .u-padding-all-s-1 {
//   padding: var(--s-1) !important;
// }

// .u-padding-all-s0 {
//   padding: var(--s0) !important;
// }

// .u-padding-all-s1 {
//   padding: var(--s1) !important;
// }

// .u-padding-all-s2 {
//   padding: var(--s2) !important;
// }

// .u-padding-all-s3 {
//   padding: var(--s3) !important;
// }

// .u-padding-all-s4 {
//   padding: var(--s4) !important;
// }

// .u-padding-all-s5 {
//   padding: var(--s5) !important;
// }

// .u-padding-all-s6 {
//   padding: var(--s6) !important;
// }

// .u-padding-all-s7 {
//   padding: var(--s7) !important;
// }

// .u-padding-all-s8 {
//   padding: var(--s8) !important;
// }

// .u-padding-all-s9 {
//   padding: var(--s9) !important;
// }

// .u-padding-all-s10 {
//   padding: var(--s10) !important;
// }

// .u-padding-horizontal-s-7 {
//   padding-left: var(--s-7) !important;
//   padding-right: var(--s-7) !important;
// }

// .u-padding-horizontal-s-6 {
//   padding-left: var(--s-6) !important;
//   padding-right: var(--s-6) !important;
// }

// .u-padding-horizontal-s-5 {
//   padding-left: var(--s-5) !important;
//   padding-right: var(--s-5) !important;
// }

// .u-padding-horizontal-s-4 {
//   padding-left: var(--s-4) !important;
//   padding-right: var(--s-4) !important;
// }

// .u-padding-horizontal-s-3 {
//   padding-left: var(--s-3) !important;
//   padding-right: var(--s-3) !important;
// }

// .u-padding-horizontal-s-2 {
//   padding-left: var(--s-2) !important;
//   padding-right: var(--s-2) !important;
// }

// .u-padding-horizontal-s-1 {
//   padding-left: var(--s-1) !important;
//   padding-right: var(--s-1) !important;
// }

// .u-padding-horizontal-s0 {
//   padding-left: var(--s0) !important;
//   padding-right: var(--s0) !important;
// }

// .u-padding-horizontal-s1 {
//   padding-left: var(--s1) !important;
//   padding-right: var(--s1) !important;
// }

// .u-padding-horizontal-s2 {
//   padding-left: var(--s2) !important;
//   padding-right: var(--s2) !important;
// }

// .u-padding-horizontal-s3 {
//   padding-left: var(--s3) !important;
//   padding-right: var(--s3) !important;
// }

// .u-padding-horizontal-s4 {
//   padding-left: var(--s4) !important;
//   padding-right: var(--s4) !important;
// }

// .u-padding-horizontal-s5 {
//   padding-left: var(--s5) !important;
//   padding-right: var(--s5) !important;
// }

// .u-padding-horizontal-s6 {
//   padding-left: var(--s6) !important;
//   padding-right: var(--s6) !important;
// }

// .u-padding-horizontal-s7 {
//   padding-left: var(--s7) !important;
//   padding-right: var(--s7) !important;
// }

// .u-padding-horizontal-s8 {
//   padding-left: var(--s8) !important;
//   padding-right: var(--s8) !important;
// }

// .u-padding-horizontal-s9 {
//   padding-left: var(--s9) !important;
//   padding-right: var(--s9) !important;
// }

// .u-padding-horizontal-s10 {
//   padding-left: var(--s10) !important;
//   padding-right: var(--s10) !important;
// }

.u-mobile-padding-horizontal {
  padding-left: var(--mobile-padding) !important;
  padding-right: var(--mobile-padding) !important;
}

// .u-padding-vertical-s-7 {
//   padding-top: var(--s-7) !important;
//   padding-bottom: var(--s-7) !important;
// }

// .u-padding-vertical-s-6 {
//   padding-top: var(--s-6) !important;
//   padding-bottom: var(--s-6) !important;
// }

// .u-padding-vertical-s-5 {
//   padding-top: var(--s-5) !important;
//   padding-bottom: var(--s-5) !important;
// }

// .u-padding-vertical-s-4 {
//   padding-top: var(--s-4) !important;
//   padding-bottom: var(--s-4) !important;
// }

// .u-padding-vertical-s-3 {
//   padding-top: var(--s-3) !important;
//   padding-bottom: var(--s-3) !important;
// }

// .u-padding-vertical-s-2 {
//   padding-top: var(--s-2) !important;
//   padding-bottom: var(--s-2) !important;
// }

// .u-padding-vertical-s-1 {
//   padding-top: var(--s-1) !important;
//   padding-bottom: var(--s-1) !important;
// }

// .u-padding-vertical-s0 {
//   padding-top: var(--s0) !important;
//   padding-bottom: var(--s0) !important;
// }

// .u-padding-vertical-s1 {
//   padding-top: var(--s1) !important;
//   padding-bottom: var(--s1) !important;
// }

// .u-padding-vertical-s2 {
//   padding-top: var(--s2) !important;
//   padding-bottom: var(--s2) !important;
// }

// .u-padding-vertical-s3 {
//   padding-top: var(--s3) !important;
//   padding-bottom: var(--s3) !important;
// }

// .u-padding-vertical-s4 {
//   padding-top: var(--s4) !important;
//   padding-bottom: var(--s4) !important;
// }

// .u-padding-vertical-s5 {
//   padding-top: var(--s5) !important;
//   padding-bottom: var(--s5) !important;
// }

// .u-padding-vertical-s6 {
//   padding-top: var(--s6) !important;
//   padding-bottom: var(--s6) !important;
// }

// .u-padding-vertical-s7 {
//   padding-top: var(--s7) !important;
//   padding-bottom: var(--s7) !important;
// }

// .u-padding-vertical-s8 {
//   padding-top: var(--s8) !important;
//   padding-bottom: var(--s8) !important;
// }

// .u-padding-vertical-s9 {
//   padding-top: var(--s9) !important;
//   padding-bottom: var(--s9) !important;
// }

// .u-padding-vertical-s10 {
//   padding-top: var(--s10) !important;
//   padding-bottom: var(--s10) !important;
// }

// .u-padding-top-s-7 {
//   padding-top: var(--s-7) !important;
// }

// .u-padding-top-s-6 {
//   padding-top: var(--s-6) !important;
// }

// .u-padding-top-s-5 {
//   padding-top: var(--s-5) !important;
// }

// .u-padding-top-s-4 {
//   padding-top: var(--s-4) !important;
// }

// .u-padding-top-s-3 {
//   padding-top: var(--s-3) !important;
// }

// .u-padding-top-s-2 {
//   padding-top: var(--s-2) !important;
// }

// .u-padding-top-s-1 {
//   padding-top: var(--s-1) !important;
// }

// .u-padding-top-s0 {
//   padding-top: var(--s0) !important;
// }

// .u-padding-top-s1 {
//   padding-top: var(--s1) !important;
// }

// .u-padding-top-s2 {
//   padding-top: var(--s2) !important;
// }

// .u-padding-top-s3 {
//   padding-top: var(--s3) !important;
// }

// .u-padding-top-s4 {
//   padding-top: var(--s4) !important;
// }

// .u-padding-top-s5 {
//   padding-top: var(--s5) !important;
// }

// .u-padding-top-s6 {
//   padding-top: var(--s6) !important;
// }

// .u-padding-top-s7 {
//   padding-top: var(--s7) !important;
// }

// .u-padding-top-s8 {
//   padding-top: var(--s8) !important;
// }

// .u-padding-top-s9 {
//   padding-top: var(--s9) !important;
// }

.u-padding-top-s13 {
  padding-top: var(--s13) !important;
}

// .u-padding-top-none {
//   padding-top: 0 !important;
// }

// .u-padding-left-s-7 {
//   padding-left: var(--s-7) !important;
// }

// .u-padding-left-s-6 {
//   padding-left: var(--s-6) !important;
// }

// .u-padding-left-s-5 {
//   padding-left: var(--s-5) !important;
// }

// .u-padding-left-s-4 {
//   padding-left: var(--s-4) !important;
// }

// .u-padding-left-s-3 {
//   padding-left: var(--s-3) !important;
// }

// .u-padding-left-s-2 {
//   padding-left: var(--s-2) !important;
// }

// .u-padding-left-s-1 {
//   padding-left: var(--s-1) !important;
// }

// .u-padding-left-s0 {
//   padding-left: var(--s0) !important;
// }

// .u-padding-left-s1 {
//   padding-left: var(--s1) !important;
// }

// .u-padding-left-s2 {
//   padding-left: var(--s2) !important;
// }

// .u-padding-left-s3 {
//   padding-left: var(--s3) !important;
// }

// .u-padding-left-s4 {
//   padding-left: var(--s4) !important;
// }

// .u-padding-left-s5 {
//   padding-left: var(--s5) !important;
// }

// .u-padding-left-s6 {
//   padding-left: var(--s6) !important;
// }

// .u-padding-left-s7 {
//   padding-left: var(--s7) !important;
// }

// .u-padding-left-s8 {
//   padding-left: var(--s8) !important;
// }

// .u-padding-left-s9 {
//   padding-left: var(--s9) !important;
// }

// .u-padding-left-s10 {
//   padding-left: var(--s10) !important;
// }

// .u-padding-left-none {
//   padding-left: 0 !important;
// }

// .u-padding-bottom-s-7 {
//   padding-bottom: var(--s-7) !important;
// }

// .u-padding-bottom-s-6 {
//   padding-bottom: var(--s-6) !important;
// }

// .u-padding-bottom-s-5 {
//   padding-bottom: var(--s-5) !important;
// }

// .u-padding-bottom-s-4 {
//   padding-bottom: var(--s-4) !important;
// }

// .u-padding-bottom-s-3 {
//   padding-bottom: var(--s-3) !important;
// }

// .u-padding-bottom-s-2 {
//   padding-bottom: var(--s-2) !important;
// }

// .u-padding-bottom-s-1 {
//   padding-bottom: var(--s-1) !important;
// }

// .u-padding-bottom-s0 {
//   padding-bottom: var(--s0) !important;
// }

// .u-padding-bottom-s1 {
//   padding-bottom: var(--s1) !important;
// }

// .u-padding-bottom-s2 {
//   padding-bottom: var(--s2) !important;
// }

// .u-padding-bottom-s3 {
//   padding-bottom: var(--s3) !important;
// }

// .u-padding-bottom-s4 {
//   padding-bottom: var(--s4) !important;
// }

// .u-padding-bottom-s5 {
//   padding-bottom: var(--s5) !important;
// }

// .u-padding-bottom-s6 {
//   padding-bottom: var(--s6) !important;
// }

// .u-padding-bottom-s7 {
//   padding-bottom: var(--s7) !important;
// }

// .u-padding-bottom-s8 {
//   padding-bottom: var(--s8) !important;
// }

// .u-padding-bottom-s9 {
//   padding-bottom: var(--s9) !important;
// }

// .u-padding-bottom-s10 {
//   padding-bottom: var(--s10) !important;
// }

.u-padding-bottom-none {
  padding-bottom: 0;
}

// .u-padding-right-s-7 {
//   padding-right: var(--s-7) !important;
// }

// .u-padding-right-s-6 {
//   padding-right: var(--s-6) !important;
// }

// .u-padding-right-s-5 {
//   padding-right: var(--s-5) !important;
// }

// .u-padding-right-s-4 {
//   padding-right: var(--s-4) !important;
// }

// .u-padding-right-s-3 {
//   padding-right: var(--s-3) !important;
// }

// .u-padding-right-s-2 {
//   padding-right: var(--s-2) !important;
// }

// .u-padding-right-s-1 {
//   padding-right: var(--s-1) !important;
// }

// .u-padding-right-s0 {
//   padding-right: var(--s0) !important;
// }

// .u-padding-right-s1 {
//   padding-right: var(--s1) !important;
// }

// .u-padding-right-s2 {
//   padding-right: var(--s2) !important;
// }

// .u-padding-right-s3 {
//   padding-right: var(--s3) !important;
// }

// .u-padding-right-s4 {
//   padding-right: var(--s4) !important;
// }

// .u-padding-right-s5 {
//   padding-right: var(--s5) !important;
// }

// .u-padding-right-s6 {
//   padding-right: var(--s6) !important;
// }

// .u-padding-right-s7 {
//   padding-right: var(--s7) !important;
// }

// .u-padding-right-s8 {
//   padding-right: var(--s8) !important;
// }

// .u-padding-right-s9 {
//   padding-right: var(--s9) !important;
// }

// .u-padding-right-s10 {
//   padding-right: var(--s10) !important;
// }

// .u-padding-right-none {
//   padding-right: 0 !important;
// }

.u-padding-block-s12 {
  padding-block: var(--s12);
}

.u-padding-block-s15 {
  padding-block: var(--s15);
}

// .u-margin-all-s-7 {
//   margin: var(--s-7) !important;
// }

// .u-margin-all-s-6 {
//   margin: var(--s-6) !important;
// }

// .u-margin-all-s-5 {
//   margin: var(--s-5) !important;
// }

// .u-margin-all-s-4 {
//   margin: var(--s-4) !important;
// }

// .u-margin-all-s-3 {
//   margin: var(--s-3) !important;
// }

// .u-margin-all-s-2 {
//   margin: var(--s-2) !important;
// }

// .u-margin-all-s-1 {
//   margin: var(--s-1) !important;
// }

// .u-margin-all-s0 {
//   margin: var(--s0) !important;
// }

// .u-margin-all-s1 {
//   margin: var(--s1) !important;
// }

// .u-margin-all-s2 {
//   margin: var(--s2) !important;
// }

// .u-margin-all-s3 {
//   margin: var(--s3) !important;
// }

// .u-margin-all-s4 {
//   margin: var(--s4) !important;
// }

// .u-margin-all-s5 {
//   margin: var(--s5) !important;
// }

// .u-margin-all-s6 {
//   margin: var(--s6) !important;
// }

// .u-margin-all-s7 {
//   margin: var(--s7) !important;
// }

// .u-margin-all-s8 {
//   margin: var(--s8) !important;
// }

// .u-margin-all-s9 {
//   margin: var(--s9) !important;
// }

// .u-margin-all-s10 {
//   margin: var(--s10) !important;
// }

// .u-margin-horizontal-s-7 {
//   margin-left: var(--s-7) !important;
//   margin-right: var(--s-7) !important;
// }

// .u-margin-horizontal-s-6 {
//   margin-left: var(--s-6) !important;
//   margin-right: var(--s-6) !important;
// }

// .u-margin-horizontal-s-5 {
//   margin-left: var(--s-5) !important;
//   margin-right: var(--s-5) !important;
// }

// .u-margin-horizontal-s-4 {
//   margin-left: var(--s-4) !important;
//   margin-right: var(--s-4) !important;
// }

// .u-margin-horizontal-s-3 {
//   margin-left: var(--s-3) !important;
//   margin-right: var(--s-3) !important;
// }

// .u-margin-horizontal-s-2 {
//   margin-left: var(--s-2) !important;
//   margin-right: var(--s-2) !important;
// }

// .u-margin-horizontal-s-1 {
//   margin-left: var(--s-1) !important;
//   margin-right: var(--s-1) !important;
// }

// .u-margin-horizontal-s0 {
//   margin-left: var(--s0) !important;
//   margin-right: var(--s0) !important;
// }

// .u-margin-horizontal-s1 {
//   margin-left: var(--s1) !important;
//   margin-right: var(--s1) !important;
// }

// .u-margin-horizontal-s2 {
//   margin-left: var(--s2) !important;
//   margin-right: var(--s2) !important;
// }

// .u-margin-horizontal-s3 {
//   margin-left: var(--s3) !important;
//   margin-right: var(--s3) !important;
// }

// .u-margin-horizontal-s4 {
//   margin-left: var(--s4) !important;
//   margin-right: var(--s4) !important;
// }

// .u-margin-horizontal-s5 {
//   margin-left: var(--s5) !important;
//   margin-right: var(--s5) !important;
// }

// .u-margin-horizontal-s6 {
//   margin-left: var(--s6) !important;
//   margin-right: var(--s6) !important;
// }

// .u-margin-horizontal-s7 {
//   margin-left: var(--s7) !important;
//   margin-right: var(--s7) !important;
// }

// .u-margin-horizontal-s8 {
//   margin-left: var(--s8) !important;
//   margin-right: var(--s8) !important;
// }

// .u-margin-horizontal-s9 {
//   margin-left: var(--s9) !important;
//   margin-right: var(--s9) !important;
// }

// .u-margin-horizontal-s10 {
//   margin-left: var(--s10) !important;
//   margin-right: var(--s10) !important;
// }

.u-margin-horizontal-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

// .u-margin-vertical-s-7 {
//   margin-top: var(--s-7) !important;
//   margin-bottom: var(--s-7) !important;
// }

// .u-margin-vertical-s-6 {
//   margin-top: var(--s-6) !important;
//   margin-bottom: var(--s-6) !important;
// }

// .u-margin-vertical-s-5 {
//   margin-top: var(--s-5) !important;
//   margin-bottom: var(--s-5) !important;
// }

// .u-margin-vertical-s-4 {
//   margin-top: var(--s-4) !important;
//   margin-bottom: var(--s-4) !important;
// }

// .u-margin-vertical-s-3 {
//   margin-top: var(--s-3) !important;
//   margin-bottom: var(--s-3) !important;
// }

// .u-margin-vertical-s-2 {
//   margin-top: var(--s-2) !important;
//   margin-bottom: var(--s-2) !important;
// }

// .u-margin-vertical-s-1 {
//   margin-top: var(--s-1) !important;
//   margin-bottom: var(--s-1) !important;
// }

// .u-margin-vertical-s0 {
//   margin-top: var(--s0) !important;
//   margin-bottom: var(--s0) !important;
// }

// .u-margin-vertical-s1 {
//   margin-top: var(--s1) !important;
//   margin-bottom: var(--s1) !important;
// }

// .u-margin-vertical-s2 {
//   margin-top: var(--s2) !important;
//   margin-bottom: var(--s2) !important;
// }

// .u-margin-vertical-s3 {
//   margin-top: var(--s3) !important;
//   margin-bottom: var(--s3) !important;
// }

// .u-margin-vertical-s4 {
//   margin-top: var(--s4) !important;
//   margin-bottom: var(--s4) !important;
// }

// .u-margin-vertical-s5 {
//   margin-top: var(--s5) !important;
//   margin-bottom: var(--s5) !important;
// }

// .u-margin-vertical-s6 {
//   margin-top: var(--s6) !important;
//   margin-bottom: var(--s6) !important;
// }

// .u-margin-vertical-s7 {
//   margin-top: var(--s7) !important;
//   margin-bottom: var(--s7) !important;
// }

// .u-margin-vertical-s8 {
//   margin-top: var(--s8) !important;
//   margin-bottom: var(--s8) !important;
// }

// .u-margin-vertical-s9 {
//   margin-top: var(--s9) !important;
//   margin-bottom: var(--s9) !important;
// }

// .u-margin-vertical-s10 {
//   margin-top: var(--s10) !important;
//   margin-bottom: var(--s10) !important;
// }

// .u-margin-vertical-auto {
//   margin-top: auto !important;
//   margin-bottom: auto !important;
// }

// .u-margin-top-s-7 {
//   margin-top: var(--s-7) !important;
// }

// .u-margin-top-s-6 {
//   margin-top: var(--s-6) !important;
// }

// .u-margin-top-s-5 {
//   margin-top: var(--s-5) !important;
// }

// .u-margin-top-s-4 {
//   margin-top: var(--s-4) !important;
// }

// .u-margin-top-s-3 {
//   margin-top: var(--s-3) !important;
// }

// .u-margin-top-s-2 {
//   margin-top: var(--s-2) !important;
// }

// .u-margin-top-s-1 {
//   margin-top: var(--s-1) !important;
// }

// .u-margin-top-s0 {
//   margin-top: var(--s0) !important;
// }

// .u-margin-top-s1 {
//   margin-top: var(--s1) !important;
// }

// .u-margin-top-s2 {
//   margin-top: var(--s2) !important;
// }

// .u-margin-top-s3 {
//   margin-top: var(--s3) !important;
// }

// .u-margin-top-s4 {
//   margin-top: var(--s4) !important;
// }

// .u-margin-top-s5 {
//   margin-top: var(--s5) !important;
// }

// .u-margin-top-s6 {
//   margin-top: var(--s6) !important;
// }

// .u-margin-top-s7 {
//   margin-top: var(--s7) !important;
// }

// .u-margin-top-s8 {
//   margin-top: var(--s8) !important;
// }

// .u-margin-top-s9 {
//   margin-top: var(--s9) !important;
// }

.u-margin-top-s10 {
  margin-top: var(--s10);
}

.u-margin-top-s11 {
  margin-top: var(--s11) !important;
}

.u-margin-top-s12 {
  margin-top: var(--s12);
}

.u-margin-top-s15 {
  margin-top: var(--s15);
}

.u-margin-top-none {
  margin-top: 0;
}

// .u-margin-top-auto {
//   margin-top: auto !important;
// }

// .u-margin-left-s-7 {
//   margin-left: var(--s-7) !important;
// }

// .u-margin-left-s-6 {
//   margin-left: var(--s-6) !important;
// }

// .u-margin-left-s-5 {
//   margin-left: var(--s-5) !important;
// }

// .u-margin-left-s-4 {
//   margin-left: var(--s-4) !important;
// }

// .u-margin-left-s-3 {
//   margin-left: var(--s-3) !important;
// }

// .u-margin-left-s-2 {
//   margin-left: var(--s-2) !important;
// }

// .u-margin-left-s-1 {
//   margin-left: var(--s-1) !important;
// }

// .u-margin-left-s0 {
//   margin-left: var(--s0) !important;
// }

// .u-margin-left-s1 {
//   margin-left: var(--s1) !important;
// }

// .u-margin-left-s2 {
//   margin-left: var(--s2) !important;
// }

// .u-margin-left-s3 {
//   margin-left: var(--s3) !important;
// }

// .u-margin-left-s4 {
//   margin-left: var(--s4) !important;
// }

// .u-margin-left-s5 {
//   margin-left: var(--s5) !important;
// }

// .u-margin-left-s6 {
//   margin-left: var(--s6) !important;
// }

// .u-margin-left-s7 {
//   margin-left: var(--s7) !important;
// }

// .u-margin-left-s8 {
//   margin-left: var(--s8) !important;
// }

// .u-margin-left-s9 {
//   margin-left: var(--s9) !important;
// }

// .u-margin-left-s10 {
//   margin-left: var(--s10) !important;
// }

// .u-margin-left-none {
//   margin-left: 0 !important;
// }

.u-margin-left-auto {
  margin-left: auto !important;
}

// .u-margin-bottom-s-7 {
//   margin-bottom: var(--s-7) !important;
// }

// .u-margin-bottom-s-6 {
//   margin-bottom: var(--s-6) !important;
// }

// .u-margin-bottom-s-5 {
//   margin-bottom: var(--s-5) !important;
// }

// .u-margin-bottom-s-4 {
//   margin-bottom: var(--s-4) !important;
// }

// .u-margin-bottom-s-3 {
//   margin-bottom: var(--s-3) !important;
// }

// .u-margin-bottom-s-2 {
//   margin-bottom: var(--s-2) !important;
// }

// .u-margin-bottom-s-1 {
//   margin-bottom: var(--s-1) !important;
// }

// .u-margin-bottom-s0 {
//   margin-bottom: var(--s0) !important;
// }

// .u-margin-bottom-s1 {
//   margin-bottom: var(--s1) !important;
// }

// .u-margin-bottom-s2 {
//   margin-bottom: var(--s2) !important;
// }

// .u-margin-bottom-s3 {
//   margin-bottom: var(--s3) !important;
// }

// .u-margin-bottom-s4 {
//   margin-bottom: var(--s4) !important;
// }

// .u-margin-bottom-s5 {
//   margin-bottom: var(--s5) !important;
// }

// .u-margin-bottom-s6 {
//   margin-bottom: var(--s6) !important;
// }

// .u-margin-bottom-s7 {
//   margin-bottom: var(--s7) !important;
// }

// .u-margin-bottom-s8 {
//   margin-bottom: var(--s8) !important;
// }

// .u-margin-bottom-s9 {
//   margin-bottom: var(--s9) !important;
// }

// .u-margin-bottom-s10 {
//   margin-bottom: var(--s10) !important;
// }

// .u-margin-bottom-none {
//   margin-bottom: 0 !important;
// }

// .u-margin-bottom-auto {
//   margin-bottom: auto !important;
// }

// .u-margin-right-s-7 {
//   margin-right: var(--s-7) !important;
// }

// .u-margin-right-s-6 {
//   margin-right: var(--s-6) !important;
// }

@include breakpoints.get(48) {
  .u-margin-top-s15-bp48 {
    margin-top: var(--s15);
  }

  .u-margin-top-s16-bp48 {
    margin-top: var(--s16);
  }

  .u-margin-top-s18-bp48 {
    margin-top: var(--s18);
  }
  
  .u-padding-bottom-s13-bp48 {
    padding-top: var(--s13);
  }
  
  .u-padding-block-s15-bp48 {
    padding-block: var(--s15);
  }
  
  .u-padding-block-s18-bp48 {
    padding-block: var(--s18);
  }
}
