@use "../0-settings/breakpoints";

body {
  font-family: var(--font);
  line-height: 1.75;
  font-size: var(--s2);
  width: 100%;
}

h1 {
  font-size: clamp(var(--s7), 9.25vw, var(--s18));
  font-weight: 900;
  line-height: clamp(var(--s9), 8.6vw, calc(var(--s18) * 0.93));
  text-transform: uppercase;
  word-break: break-all;

  @include breakpoints.get(30) {
    word-break: normal;
  }
}

button {
  background: none;
  border: none;
}

svg {
  width: auto;
}

svg text {
  font-family: var(--font);
}

a {
  color: inherit;
}

img {
  width: 100%;
}

video {
  width: 100%;
  max-width: 100%;
}

main {
  position: relative;
  z-index: 1;
}
main > * {
  position: relative;
  z-index: 3;
}
