html {
  scroll-padding-top: 134px;
}
body {
  font-family: var(--sofia-pro);
}

ul {
  padding-left: var(--s0);
  margin: 0;
}

ul li::marker {
  color: var(--list-bullet-color, initial);
} 

img {
  width: auto;
}