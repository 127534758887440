@use '../0-settings/breakpoints';


.brand-row {
  --brand-row-justify: center;
  --brand-row-gap: var(--s6);
  width: 100%;
  padding-block: var(--s10);
  flex-direction: column;

  @include breakpoints.get(48) {
    --brand-row-justify: space-between;
    --brand-row-gap: var(--s3);
    flex-direction: row;
  }
}