@use "./animation" as *;
@use "./breakpoints" as *;
@use "./colors" as *;
@use "./fonts" as *;
@use "./header" as *;
@use "./offcanvas" as *;
@use "./scale" as *;
@use "./top-bar" as *;

:root {
  @each $color, $hex in $colors {
    --#{$color}: #{$hex};
  }
}

:root {
  @each $key, $value in $breakpoints {
    --bp-#{$key}: #{$value}rem;
  }
}

:root {
  @each $scale, $value in $sizes {
    --#{$scale}: #{$value}rem;
  }
}

:root {
  @each $scale, $value in $globals {
    --#{$scale}: #{$value};
  }
}

:root {
  --font: #{$sans-serif};
  --sofia-pro: #{$sofia-pro};
  --dm-serif-text: #{$dm-serif-text};
}

:root {
  --header-angle-height: #{$header-angle-height};
  --top-bar-height: #{$top-bar-height}rem;
  --offcanvas-endstate-bp: #{$offcanvas-endstate-bp}em;
  --enable-animation-breakpoint: #{$enable-animation-breakpoint};
  --mobile-breakpoint: #{$enable-animation-breakpoint};
}
