@use '../../0-settings/breakpoints';
@use '../../0-settings/paths' as *;
@use '../../0-settings/scale' as *;
@use '../../0-settings/clamp-helper' as *;

.hero {
  min-height: clamp(40rem, #{getVW(51.875)}, 51.875rem);
  background-color: var(--dark-blue);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: image-set(url(#{$base-image-path}/2023/grain-texture/img.webp) 1x, url(#{$base-image-path}/2023/grain-texture/img@2x.webp) 2x);
    mix-blend-mode: overlay;
    z-index: 5;
  }
}

.hero-heading {
  font-size: var(--s6);
  line-height: 1;
  color: white;
  font-weight: 900;
  text-align: center;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
}

.hero-heading.-large {
  $max: calc-rem(146);
  $min: calc-rem(65);


  font-size: clamp(#{$min}rem, #{getVW($max)}, #{$max}rem);

  @include breakpoints.get(64) {
    margin-top: auto;
  }

}

.hero-heading.-small {
  $max: calc-rem(95);
  $min: calc-rem(42);

  font-size: clamp(#{$min}rem, #{getVW($max)}, #{$max}rem);
  font-weight: 900;
  mix-blend-mode: difference;
}

.hero .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  z-index: 1;
}

.hero .bg .dot-grid {
  position: absolute;
  width: 302px;
  height: 302px;
}

.hero .bg .dot-grid.top {
  top: 0;
  right: 0;
  translate: 0 50%;
}

.hero .bg .dot-grid.bottom {
  bottom: 0;
  left: 0;
  translate: 100% 40%;
}

.hero .bg .purple-blob {
  position: absolute;
  max-width: none;
  width: 800px;
  translate: -34% 30%;
  left: 0;
  bottom: 0;
  
  
  @include breakpoints.get(40) {
    width: 95%;
    max-width: 1100px;
    bottom: 0;
    right: auto;
    translate: -50% 20%;
    left: 50%;
  }

  @include breakpoints.get(53em) {
    translate: -50% 35%;
  }

  @include breakpoints.get(90) {
    right: 20%;
    left: auto;
    translate: 0 35%;
    width: auto;
  }
}