.main-menu a {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--duck-egg-blue-darkened);
  font-size: clamp(var(--s4), 4.85vw, var(--s7));
  font-weight: 900;
  line-height: 1.05;
}

.main-menu a:hover {
  color: var(--black);
  filter: brightness(1);
}
