@use '../../0-settings/paths' as *;

.volunteer-profile {
  color: var(--white);
  border-color: var(--white);
  border-width: 1px 0 1px 0;
  border-style: solid;
  padding-block: var(--s10);

  &:last-child {
    border: none;
  }
}

.volunteer-profile .headshot {
  max-width: 171px;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    background-image: url(#{$base-image-path}/2023/volunteer-profile-headshot-bg.svg);
    background-size: cover;
    width: 153px;
    height: 159px;
    position: absolute;
    top: 0;
    left: 0;
    translate: 0 -16px;
    z-index: 1;
  }

  img {
    position: relative;
    z-index: 2;
    max-width: 155px;
    margin-left: auto;
  }
}

.volunteer-profile h3 {
  color: var(--highlighter-yellow-green);
  font-size: var(--s5);
  font-weight: 900;
  line-height: 1.1;
}