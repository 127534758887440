@use "sass:math";

/**
 * Currently using the Major Third scale.
 * Reference: https://type-scale.com
*/
$ratio: 1.125;
$base-size: 16;

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: math.div($value, $number);
    }
  }

  @return $value;
}

@function calc-rem($px) {
  @return math.div($px, $base-size);
}

@function get($size) {
    @return map-get($sizes, $size);
}

/* prettier-ignore */
$sizes: (
  's-7': pow($ratio, -7), // 0.438rem / 7.02px
  's-6': pow($ratio, -6), // 0.493rem / 7.89px
  's-5': pow($ratio, -5), // 0.555rem / 8.88px
  's-4': pow($ratio, -4), // 0.624rem / 9.99px
  's-3': pow($ratio, -3), // 0.702rem / 11.24px
  's-2': pow($ratio, -2), // 0.79rem  / 12.64px
  's-1': pow($ratio, -1), // 0.889rem / 14.22px
  's0': 1,                // 1rem / 16px       
  's1': pow($ratio, 1),   // 1.125rem / 18.00px
  's2': pow($ratio, 2),   // 1.266rem / 20.25px  
  's3': pow($ratio, 3),   // 1.424rem / 22.78px
  's4': pow($ratio, 4),   // 1.602rem / 25.63px
  's5': pow($ratio, 5),   // 1.802rem / 28.83px
  's6': pow($ratio, 6),   // 2.027rem / 32.44px
  's7': pow($ratio, 7),   // 2.281rem / 36.49px
  's8': pow($ratio, 8),   // 2.566rem / 41.05px
  's9': pow($ratio, 9),   // 2.887rem / 46.18px
  's10': pow($ratio, 10), // 3.247rem / 51.96px
  's11': pow($ratio, 11), // 3.653rem / 58.45px
  's12': pow($ratio, 12), // 4.11rem  / 65.76px
  's13': pow($ratio, 13), // 4.624rem / 73.98px
  's14': pow($ratio, 14), // 5.202rem / 83.23px
  's15': pow($ratio, 15), // 5.852rem / 93.63px
  's16': pow($ratio, 16), // 6.583rem / 105.33px
  's17': pow($ratio, 17), // 7.406rem / 118.50px
  's18': pow($ratio, 18), // 8.332rem / 133.31px
  's19': pow($ratio, 19), // 9.373rem / 149.97px
);

$globals: (
  "vertical-rhythm": var(--s0),
  "mobile-padding": var(--s6),
);
