@use 'sass:map';
@use '../../0-settings/clamp' as *;
@use '../../0-settings/scale' as *;

.case-study-2023 {
  position: relative;
  overflow: hidden;
}

.case-study-2023 .case-study-hero-text {
  width: 100%;
}
.case-study-2023 .case-study-hero-text .heading {
  color: var(--white);
}

.case-study-2023 .case-study-hero-text .subheading {
  mix-blend-mode: screen;
}

.case-study-2023 .case-study-hero-text {
  position: absolute;
  left: 0;
  bottom: clamp(var(--s8), #{getVW(map.get($sizes, 's16'))}, var(--s16));
  z-index: 3;
}

.case-study-2023.-giants-community-fund .dot-grid {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  
  + * {
    position: relative;
    z-index: 2;
  }
}

.case-study-2023 h2 {
  color: var(--white);
  line-height: 1.1;
}

.case-study-2023.-giants-community-fund h2 {
  max-width: 15ch;
}