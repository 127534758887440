@use '../0-settings/animation';
@use '../0-settings/breakpoints';
@use '../0-settings/scale';

.animated-heading{
  overflow: hidden;
}

.animated-heading > * {
  width: max-content;
  line-height: 0.9;
  text-transform: uppercase;
  font-size: clamp(var(--s8), 5.77vw, var(--s14));
  font-weight: 900;
}

.animated-heading > *:not(h2, h3) {
  display: none;

  @include breakpoints.get(#{animation.$enable-animation-breakpoint}) {
    display: block;
  }
}

.animated-heading :is(h2, h3) {
  display: block;
  padding-left: var(--mobile-padding);
  padding-right: var(--mobile-padding);
  width: auto;
  height: auto;
  position: static;
  overflow:visible;
  
  @include breakpoints.get(#{animation.$enable-animation-breakpoint}) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.animated-heading h3 {
  font-size: var(--s5);
  line-height: 1.2;
}

.animated-heading .subheading {
  font-size: var(--s10);
  font-weight: 800;
}