.offcanvas-icon rect {
  fill: var(--highlighter-yellow-green);
}

.offcanvas-icon-open rect {
  fill: var(--black);
}

.offcanvas {
  background-color: var(--highlighter-yellow-green);
}

.offcanvas-footer {
  margin-block: 0 auto;
}