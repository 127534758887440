@use '../../0-settings/paths' as *;

.blue-bg-with-grainy-texture {
  background-color: var(--dark-blue);
  background-image: image-set(url(#{$base-image-path}/2023/grain-texture/img.webp) 1x, url(#{$base-image-path}/2023/grain-texture/img@2x.webp) 2x);
  background-blend-mode: overlay;
}

.bg-green-triangle {
  background-image: url(#{$base-image-path}/2023/bg-green-triangle/img.webp);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 328px auto;
}