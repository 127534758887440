@use '../../0-settings/breakpoints' as *;
@use 'sass:map';
@use '../../0-settings/clamp' as *;
@use '../../0-settings/scale' as *;

.stats-heading {
  color: var(--white);
  font-size: clamp(var(--s6), #{getVW(map.get($sizes, 's10'))}, var(--s10));
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.1875rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  color: var(--white);
  text-align: center;
  grid-column-gap: var(--s6);
  grid-row-gap: var(--s14);

  @include get(30) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include get(48) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.stats-grid .number {
  font-family: var(--dm-serif-text);
  font-size: var(--s12);
  line-height: 1;
}

.stats-grid .description {
  text-transform: uppercase;
  font-size: var(--s1);
  line-height: 1.1;
}